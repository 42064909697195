<template>
  <div id="content">
    <div class="bj">
      <div class="table">
          <div class="list list_ tips">
             <span style="color:#CC3333">温馨提示:</span>部分网站服务器位于国外,所以存在无法访问的情况,可以使用下面这款免费加速器进行代理访问,
             成功率提高100%,推荐使用谷歌浏览器访问！<br>
             <span style="color:#CC3333;font-size:14px">以下App会员账号,单独解锁帖子,请联系我们!</span><br>
             QQ：468433164
          </div>
          <!--  -->
             <div class="list  threeBox">
              <div @click="lx" class="threeBox_ font_tips" style="width:33%">
                      会员账号<br>
                      解锁茶贴<br>
                      帮忙预约<br> 
                      立即联系<br>
              </div>
              <div @click="gg" class="threeBox_" style="width:33%">
                      谷歌浏览器<br>
                      点击下载<br> 
                      提取密码<br>
                      e5bd      
              </div>
              <div @click="jsq" class="threeBox_" style="width:33%">
                      加速器<br>
                      IPLCQ<br>
                      内置教程<br> 
                      免费体验<br> 
              </div>
            </div>
          <!--  -->
        <!--  -->
          <div class="list" @click="fl">
            <div class="left">
              <img src="../../assets/two.jpg" alt="">
            </div>
            <div class="right">
              <!--  -->
             <div class="title">51风流<span style="color:#fff">( 51ym.xyz )</span></div>
             <!-- <div class="btn">立即进入</div> -->
             <div class="font">85451人推荐</div>
             <div class="font_1">
              当地楼凤精准打击，实力验证楼凤贴，真实无套路，品质无忧...
             </div>
            </div>
          </div>
          <!--  -->
            <div class="list" @click="ypl">
             <div class="left">
              <img src="../../assets/five.png" alt="">
            </div>
            <div class="right">
             <div class="title">一品楼<span style="color:#fff">( th2024.cc )</span></div>
             <div class="font">75616人推荐</div>
             <div class="font_1">
              欢迎来到一品楼性息交流论坛,建议使用Choreme非国产浏览器进行访问...
             </div>
              
            </div>
          </div>
          <!--  -->
          <div class="list" @click="cg">
             <div class="left">
              <img src="../../assets/one.jpg" alt="">
            </div>
            <div class="right">
             <div class="title">51茶馆</div>
             <div class="font">51429人推荐</div>
             <div class="font_1">
              找外围楼凤就上51茶馆，学生、护士一应俱全，中介靠谱安排，方便安全...
             </div>
            </div>
          </div>
          <!--  -->
          <div class="list" @click="qm">
             <div class="left">
              <img src="../../assets/three.jpg" alt="">
            </div>
            <div class="right">
             <div class="title">千妹儿</div>
             <div class="font">32872人推荐</div>
             <div class="font_1">
              同城约炮，定位附近，学生、护士一应俱全，无中介套路，方便安全...
             </div>
              
            </div>
          </div>
          <!--  -->
           <div class="list" @click="lgw">
             <div class="left">
              <img src="../../assets/four.jpg" alt="">
            </div>
            <div class="right">
             <div class="title">老哥稳</div>
             <div class="font">56891人推荐</div>
             <div class="font_1">
              老哥稳小姐威客网,最全的小姐姐信息,夜场娱乐信息大全...
             </div>
              
            </div>
          </div>
          <!--  -->
          <!-- https://www.thlbbs.cc/ypl.apk -->
        
          <!--  -->
         

             <!-- <div class="list_tip">
              
            </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
    methods: {
      fl(){
        window.location.href="http://51ym.xyz"
        // window.open("http://dk51.xyz")
      },
      cg(){
        window.location.href="https://be7253.nqccujw.com?code=Gbwq"
        // window.location.href="https://cg.aff005.live/?code=Gbwq"
        // window.open("https://cg.aff005.live/?code=Gbwq")
      },
      qm(){
        window.location.href="https://t.521qm.com/EKDN00"
        // window.open("http://521qlcps.cn/?C_Id=EKDN00&source=sucai0")
      },
      lgw(){
        window.location.href="http://qxwd372fy4.com:5002/?aff=548624"
        // window.open("http://nihy6566vt.xyz:5373/?aff=548624")
      },
      ypl(){
        window.location.href="https://www.th2024.cc/"
        // window.open("www.xh7.info")
      },
      gg(){
        window.location.href="https://wwz.lanzoub.com/iLNM30f0dbyj"
      },
      jsq(){
        window.location.href="https://cmacp.com/auth/register?code=UXS24z"
      },
      lx(){
        window.location.href="https://qm.qq.com/cgi-bin/qm/qr?k=22YUIQ6pmrDUzqp_lJm6cXKcuWxPxYji&authKey=SRirPoGtVw7wZgbpvR1DKcwj+N//p4TOOsRjxgZTKWJv7uuEEeRnovGK6ZATeJTJ&noverify=0"
      }

      
    },
}
</script>

<style scoped>
  #content{
    width: 100%;
    height: 100%;
  }
  #content .bj{
    /* width: 100%; */
    height: 100%;
    background: url(./bj.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
  }
  #content .bj .table{
    width: 100%;
    height: 80%;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 10px 20px;
    overflow-y: scroll;
  }
  #content .bj .table .list{
    width: 100%;
    height: 90px;
    background-color: #fff;
    margin-bottom: 12px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 5px;
    box-sizing: border-box;
  }
    #content .bj .table .list_{
    width: 100%;
    height: 95px;
    background-color: #fff;
    margin-bottom: 12px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 5px;
    box-sizing: border-box;
  }
    #content .bj .table .list_tip{
    width: 100%;
    height: 150px;
    background-color: #fff;
    margin-bottom: 12px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 5px;
    box-sizing: border-box;
  }
  #content .bj .table .list .left{
    width: 28%;
    height: 100%;
    float: left;
  }
    #content .bj .table .list .left img{
    width: 100%;
    height: 100%;
    /* float: left; */
  }
   #content .bj .table .list .right{
    width: 70%;
    height: 100%;
    float: left;
    margin-left: 5px;
    position: relative;

  }
   #content .bj .table .list .right .title{
    text-align: left;
    font-size: 16px;
    color: #ec4899;
    /* width: 50%; */
   }
   #content .bj .table .list .right .font{
    text-align: left;
    font-size: 14px;
    color: #CC3333;
   }
      #content .bj .table .list .right .font_1{
    text-align: left;
    font-size: 12px;
    color: #ccc;
   }
   #content .bj .table .list .right .btn{
    width: 60px;
    height: 30px;
    background-color: #CC3333;
    position: absolute;
    top: 0;
    right: 10px;
    line-height: 30px;
    border-radius: 5px;
    color: #fff;
  }
  .tips{
    color: #ccc;
    font-size: 13px;
    text-align: left;
  }
  .threeBox{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .threeBox .threeBox_{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ccc;
    font-size: 14px;
  }
  .threeBox .font_tips{
    color: #CC3333;
  }
</style>

